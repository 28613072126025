import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import bannerImage from '../../assets/banner-2.jpg';
import teamImge from '../../assets/team-1.jpg';
import interfaceImage from '../../assets/interface-1.jpg';
import approachImage from '../../assets/approach-1.jpg';
import styles from './style.module.scss';

interface LandingProps {
  classes?: {
    container?: string;
    content?: string;
  };
}
const Landing = ({ classes }: LandingProps) => {
  return (
    <div className={classNames(styles.container, classes?.container)}>
      <div className={styles.header}>
        <Logo className={styles.headerLogo} />
        <div className={styles.headerLogoText}>DavianSoft</div>
      </div>
      <div className={styles.content}>
        <div
          className={styles.bannerSection}
          style={{backgroundImage: `url("${bannerImage}")`}}
        >
          <div className={styles.bannerHint}>Our Mission</div>
          <div className={styles.bannerText}>
            Transform businesses with elite software solutions to maximize productivity
          </div>
        </div>
        <div className={styles.objectivesSection}>
          <div className={styles.objectivesText}>
            We offer a range of high-quality software solutions designed for businesses and individuals that maximize productivity and efficiency. Our solutions are user-friendly, customizable, and designed to scale with your business as it grows. Join our community of subscribers and take your productivity to the next level.
          </div>
        </div>
        <div className={styles.highlightsSection}>
          <div className={styles.highlight}>
            <img src={teamImge} className={styles.highlightImage} alt="team" />
            <div className={styles.highlightText}>
              Innovative software solutions crafted by a team of experienced professionals to elevate productivity.
            </div>
          </div>
          <div className={styles.highlight}>
            <img src={interfaceImage} className={styles.highlightImage} alt="interface" />
            <div className={styles.highlightText}>
              Cutting-edge technology and user-friendly interfaces to simplify workflows and enhance efficiency.
            </div>
          </div>
          <div className={styles.highlight}>
            <img src={approachImage} className={styles.highlightImage} alt="approach" />
            <div className={styles.highlightText}>
              Fresh and modern approach to software development, tailored to meet the unique needs of businesses and individuals.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.comingSoonSection}>
        <span className={styles.comingSoonText}>Coming soon, stay tuned!</span>
        <span className={styles.comingSoonText2}>_</span>
      </div>
      <div className={styles.footer}>
        <div className={styles.copyrightNotice}>© 2023 Daviansoft, Inc. All Rights Reserved</div>
      </div>
    </div>
  );
};

export default Landing;
