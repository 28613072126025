import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from 'styles/theme';
import Landing from 'components/landing';
// import PrivacyPolicy from 'components/privacy-policy';
import styles from './style.module.scss';

/** Separate out routes */
const App: React.FC = () => {
  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className={styles.stickyTopBar} />
          <div className={styles.rootContainer}>
            <div className={styles.primaryContainer}>
              <Router>
                <Routes>
                  <Route path="/" element={<Landing />} />
                  {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/cat" element={<div>!!!</div>} /> */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Router>
            </div>
            <div className={styles.footer} />
          </div>
          <div className={styles.stickyBottomBar} />
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
};

export default App;
